var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('gtr-page',{attrs:{"title":"Scan Uploads","icon":"mdi-barcode-scan"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-row',[_c('v-col',[_vm._v(" Event ID: "+_vm._s(_vm.event_id)+" ")])],1),_c('v-row',[_c('v-col',{staticClass:"btn-group"},[_c('v-btn',{staticClass:"priority-default",attrs:{"to":{ name: 'level-one.software' }}},[_vm._v(" Get Software ")]),_c('div',{staticClass:"btn-subgroup"},[(_vm.$hasPermission('general.microscanners.create'))?_c('v-btn',{staticClass:"priority-default",attrs:{"href":_vm.scanSheetUrlExhibitors,"target":"_blank"}},[_vm._v(" Scan Sheet for Exhibitors ")]):_vm._e(),(_vm.$hasPermission('general.microscanners.create'))?_c('v-btn',{staticClass:"priority-default btn",attrs:{"href":_vm.scanSheetUrlSessions,"target":"_blank"}},[_vm._v(" Scan Sheet for Sessions ")]):_vm._e(),(_vm.$hasPermission('general.microscanners.create'))?_c('v-btn',{staticClass:"priority-default btn",attrs:{"href":_vm.scanSheetUrlParticipants,"target":"_blank"}},[_vm._v(" Scan Sheet for Participants ")]):_vm._e()],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1 rounded-0",attrs:{"headers":_vm.table.headers,"items":_vm.table.items,"fixed-header":true,"loading":_vm.loading,"search":_vm.search},scopedSlots:_vm._u([{key:`item.created_at`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(new Date(item.created_at),"MM/DD/YYYY HH:mm A"))+" ")]}},{key:`item.actions`,fn:function({ item }){return [_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-link",attrs:{"text":"","link":"","ripple":false}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',{staticClass:"pt-0 pb-0"},[_c('v-list-item',{staticClass:"item",attrs:{"to":{
                      name: 'level-two.event.scans.fileview',
                      params: {
                        file_name: item.file_name,
                        deviceid: item.device_id,
                      },
                    }}},[_c('v-list-item-title',{staticClass:"gtr-f-p"},[_c('v-icon',{staticClass:"mr-1",attrs:{"dense":""}},[_vm._v("mdi-eye-outline")]),_vm._v(" View ")],1)],1)],1)],1)],1)]}}],null,true)})],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }