import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
@Component({
  name: 'GtrAdminSetEventView',
  computed: {
    ...mapState('scans', ['event_scan_data']),
    ...mapState('event', ['event'])
  },
  components: {
  }
})
export default class GtrAdminSetEventView extends Vue {
  data () {
    return {
      event_id: '',
      eventSelectedUuid: '',
      events2: [],
      search: '',
      loading: false,
      table: {
        headers: [
          { text: 'File Name', align: 'start', sortable: true, value: 'file_name' },
          { text: 'Device ID', value: 'device_id' },
          { text: 'Uploaded', value: 'created_at' },
          { text: 'Scans', value: 'count' },
          { text: 'Actions', value: 'actions', searchable: false, sortable: false, width: '90px' }
        ],
        items: []
      },
      forms: {
        showNewCompanyForm: false
      },
      companyToDelete: null
    }
  }

  @Watch('event_scan_data')
  onScanDataChange (payload: any) {
    if (payload) {
      this.$data.table.items = payload
    }
  }

  @Watch('event')
  onEventChange (payload: any) {
    if (payload) {
      this.$data.event_id = payload.event_id
    }
  }

  get scanSheetUrlParticipants () {
    return `${process.env.VUE_APP_API_URL}event/${this.$route.params.event_uuid}/scan-sheet/participants?limit=20`
  }

  get scanSheetUrlSessions () {
    return `${process.env.VUE_APP_API_URL}event/${this.$route.params.event_uuid}/scan-sheet/sessions`
  }

  get scanSheetUrlExhibitors () {
    return `${process.env.VUE_APP_API_URL}event/${this.$route.params.event_uuid}/scan-sheet/exhibitors`
  }

  async mounted () {
    this.$store.dispatch('event/fetchEvent', this.$route.params.event_uuid)
    this.$store.dispatch('scans/getAllEventScanData', { event_uuid: this.$route.params.event_uuid })
  }
}
